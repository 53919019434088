
export default {
  state: {
    user: "", // 登录的用户
    showLogin: false, // 用于控制是否显示登录组件
    showForgetPassword:false, //用于控制是否显示忘记密码组件
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getShowLogin(state) {
      return state.showLogin
    },
    getShowForgetPassword(state){
      return state.showForgetPassword
    }
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setShowLogin(state, data) {
      state.showLogin = data;
    },
    setShowForgetPassword(state, data) {
      state.showForgetPassword = data;
    }
  },
  actions: {
    setUser({ commit }, data) {
      commit('setUser', data);
    },
    setShowLogin({ commit }, data) {
      commit('setShowLogin', data);
    },
    setShowForgetPassword({ commit }, data) {
      commit('setShowForgetPassword', data);
    }
  }
}