<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      property: 'value',
    }
  },
}
</script>

<style lang="scss" scoped></style>
