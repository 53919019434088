<template>
  <div id="myLogin">
    <el-dialog title="登录" width="300px" center :close-on-click-modal="false" :visible.sync="isLogin">
      <!-- status-icon -->
      <el-form :model="LoginUser" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <!--  prefix-icon="el-icon-user-solid" -->
          <el-input clearable placeholder="请输入手机号  " v-model="LoginUser.name" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input v-if="this.$device.mobile" clearable type="number" class="hidden" placeholder="请输入密码" maxlength="18" v-model="LoginUser.pass" @blur.prevent="inputLoseFocus"></el-input>
          <el-input v-else clearable type="password" placeholder="请输入密码" maxlength="18" v-model="LoginUser.pass" @blur.prevent="inputLoseFocus"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="Login" style="width: 100%">登录</el-button>
        </el-form-item>
        <span class="forGetPassword" @click="forgetPasswordClick">忘记密码？</span>
        <span class="register" @click="goRegister">去注册</span>
      </el-form>
    </el-dialog>
    <MyRegister :register="register" @fromChild="isRegister"></MyRegister>
    <MyforgetPassword :forgetPassword="forgetPassword" @fromChild="isForgetPassword"></MyforgetPassword>
  </div>
</template>
<script>
// import { encryptMD5 } from "@/utils/cryptoUtils";
import { mapActions } from 'vuex'
import { login, updateUserInfo } from '@/api/home'
export default {
  name: 'MyLogin',
  data () {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号'))
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      const userNameRule = /[a-zA-Z0-9_]{4,15}$/
      if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField('checkPass')
        return callback()
      } else {
        return callback(new Error('请输入正确的手机号'))
      }
    }
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入密码'))
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      // /^[a-zA-Z]\w{5,17}$/
      const passwordRule = /\w{5,17}$/
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField('checkPass')
        return callback()
      } else {
        return callback(new Error('字母开头,长度6-18之间,允许字母数字和下划线'))
      }
    }
    return {
      register: false,
      forgetPassword: false,
      LoginUser: {
        name: '',
        pass: '',
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        pass: [{ validator: validatePass, trigger: 'blur' }],
      },
    }
  },
  computed: {
    // 获取vuex中的showLogin，控制登录组件是否显示
    isLogin: {
      get () {
        return this.$store.getters.getShowLogin
      },
      set (val) {
        this.$refs['ruleForm'].resetFields()
        this.setShowLogin(val)
      },
    },
  },
  methods: {
    ...mapActions(['setUser', 'setShowLogin', 'showForgetPassword']),
    inputLoseFocus () {
      setTimeout(() => {
        window.scrollTo(100, 0)
      }, 100)
    },
    async Login () {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      const valid = await new Promise((resolve) => {
        this.$refs['ruleForm'].validate((valid) => {
          resolve(valid)
        })
      })

      // 如果通过校验开始登录
      if (valid) {
        const params = {
          mobile: this.LoginUser.name,
          passWord: this.LoginUser.pass,
        }

        try {
          login(params)
            .then(({ data }) => {
              console.log(data)
              const mobile = data.loginUser.mobile
              // 登录信息存到本地
              this.updateUserInfo(mobile)
            })
            .catch((err) => {
              console.log(err)
              this.notifyError(err.data.msg)
            })
        } catch (error) {
          // console.error(error);
          // 返回登录错误
          // throw new Error("登录失败");
        }
      } else {
        // 校验未通过，返回 false
        return false
      }
    },
    //  更新用户信息
    async updateUserInfo (mobile) {
      const res = await updateUserInfo(mobile)
      // console.log(res);
      if (res.code == 0) {
        const userInfo = res.data.loginUser || ''
        let user = JSON.stringify(userInfo)
        localStorage.setItem('user', user)
        // 登录信息存到vuex
        this.setUser(userInfo)
        this.notifySucceed('登录成功！')
        // 隐藏登录组件
        this.isLogin = false
      }
    },
    goRegister () {
      this.isLogin = false
      this.register = true
    },
    forgetPasswordClick () {
      this.isLogin = false
      this.forgetPassword = true
    },
    isRegister (val) {
      this.register = val
    },
    isForgetPassword (val) {
      this.forgetPassword = val
    },
  },
}
</script>
<style scoped>
.forGetPassword {
  font-size: 13px;
  color: #666;
}
.forGetPassword:hover {
  cursor: pointer;
  color: blue;
}
.register {
  font-size: 13px;
  color: #666;
}
.register:hover {
  cursor: pointer;
  color: blue;
}
</style>
