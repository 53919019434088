
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import shoppingCart from './modules/shoppingCart'

Vue.use(Vuex)
const state = {
  orderInfo:{}
}
const mutations = {
  SET_ORDER_INFO(state, data) {
    state.orderInfo = data
  }
}
const actions = {
  setOrderInfo({ commit }, data) {
    commit('SET_ORDER_INFO', data);
  }
}

export default new Vuex.Store({
  strict: true,
  state,
  mutations,
  actions,
  modules: {
    user,
    shoppingCart
  }
})
