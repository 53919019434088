import axios from 'axios'
import { Notification, MessageBox } from 'element-ui'
import store from '@/store'
import Config from '@/settings'
import errorCode from '@/request/errorCode'
import router from "@/router";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_ENV_URL,
  timeout: Config.timeout // 请求超时时间
})

// 请求拦截器（可添加token 修改请求头等）
service.interceptors.request.use(
  config => {
    // if (getToken()) {
    //   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    config.headers['Content-Type'] = 'application/json; charset=utf-8'
    return config
  },
  error => {
    // Do something with request error
    // console.log(error) // for debug
    Promise.reject(error)
  }
)

// 响应拦截器（响应参数处理（成功及报错处理（登录过期处理）））
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject(response.data)
    } else {
      const data_code = response.data.code || response.data.status || 200
      // const msg = response.data.msg ||errorCode[data_code] || errorCode['default']
      let msg = ''
      if (response.data.code !==200) {
         msg = response.data.msg || response.data.data.msg || errorCode[data_code] || errorCode['default']
      }
      // const msg = response.data.msg || response.data.data.msg || errorCode[data_code] || errorCode['default']
      // 请求成功：后端请求标识不统一，做以下处理
      if ([200, 0, 101].includes(data_code)) {
        return response.data
      } else if (data_code === 401) {
        MessageBox.confirm(
          '登录状态已过期，您可以继续留在该页面，或者重新登录',
          '系统提示',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      } else if (data_code == 1) {
        return Promise.reject(response.data)
      } else {
        if (response.data.code === -1) {
          return Promise.reject(response.data)
        } else {
          Notification.error({
            title: msg || '服务器开小差了~'
          })
          return Promise.reject(response.data)
        }

      }
    }
  },
  error => {
    // console.log(error,"error");
    let code = 0
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        })
        return Promise.reject(error)
      }
    }
    if (code) {
      if (code === 401) {
        MessageBox.confirm(
          '登录状态已过期，您可以继续留在该页面，或者重新登录',
          '系统提示',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      } else if (code === 403) {
        router.push({ path: '/401' })
      } else {
        const errorMsg = error.response.data.message
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          })
        }
      }
    } else {
      Notification.error({
        title: '请求错误，请检查！',
        duration: 5000
      })
    }
    return Promise.reject(error)
  }
)
export default service
