import request from "@/request"

const apiBase = '/api-base' // 当前路径为 vue.config.js中的服务器代理地址
// const apiServies = '/api-servies'


// request 方法中也可设置请求头
// 也可以使用模版字符串拼接路径和参数，例如 url: `${apiBase}/ruzhu/api/index/rzGoodsList/${ids}`,

// export function del(id) {
//   return request({
//     url: 'mall/yxExpress/' + id,
//     method: 'delete',
//     headers: {
//       'Content-Type': 'application/json', // 设置请求头的Content-Type为application/json
//       'Authorization': 'Bearer ' + localStorage.getItem('token') // 设置Authorization请求头，示例中使用了localStorage中的token作为示例
//     }
//   });
// }
// 登录
export function login(data) {
  return request({
    url:`${apiBase}/ruzhu/user/login`,
    method:'post',
    data
  })
}
//更新用户信息
 export function updateUserInfo(mobile) {
    return request({
      url:`${apiBase}/ruzhu/user/getUserInfo?phone=${mobile}`,
      method:'get'
    })
 }
//注册
export function register(data) {
  return request({
    url:`${apiBase}/ruzhu/user/register`,
    method:'post',
    data
  })
}

// 检验公司域名
export function registerValidateName(data) {
  return request({
    url:`${apiBase}/ruzhu/user/selectOneUserName`,
    method:'post',
    data
  })
}
// 注册发送验证码
 export function sendSMS(data) {
    return request({
      url:`${apiBase}/front/send/sendSMS`,
      method:'post',
      data
    })
 }

// 忘记密码发送验证码
export function UpdatePasswordSendSMS(data) {
  return request({
    url:`${apiBase}/front/send/sendMsgWithUpdatePassword`,
    method:'post',
    data,
  })
}



// 修改密码
export function modificationPass(data) {
  return request({
    url:`${apiBase}/ruzhu/user/updatePassword`,
    method:'post',
    data
  })
}

// 获取首页商品列表
export function getGoodList(name) {
  return request({
    url: `${apiBase}/ruzhu/api/index/rzGoodsList?goodsName=`+name,
    method: 'get',
  })
}
// 校验手机号是否存在
export function selectOnTel(data) {
  return request({
    url:`${apiBase}/ruzhu/user/selectOneTel`,
    method:"post",
    data
  })
}

// 获取官网后缀
export function getDomainName() {
  return request({
    url:`${apiBase}/ruzhu/user/dynamicDomain `,
    method:'get'
  })
}

// 获取logo和公司官网地址

export function getOfficialDomain() {
  return request({
    url:`${apiBase}/ruzhu/user/officialDomain`,
    method:'get'
  })
}

// 修改密码时检验手机号是否存在
export function selectManageTel(data) {
  return request({
    url:`${apiBase}/ruzhu/user/selectManageTel`,
    method:"post",
    data
  })
}
// 校验域名是否存在
export function selectOneUserName(data) {
  return request({
    url:`${apiBase}/ruzhu/user/selectOneUserName`,
    method:"post",
    data
  })
}
// 获取首页秒杀商品列表
export function getHomeSeckillGoodsList() {
    return request({
      url:`${apiBase}/seckill/api/list`,
      method:'get',
    })
}
//提交code
export function wxCode(data) {
  return request({
    url:`${apiBase}/ruzhu/user/getUser?code=${data.code}&phone=${data.phone}`,
    method:'get'
  })
}
// 获取首页banner
export function getHomeBannerList() {
    return request ({
      url:`${apiBase}/jy/jyCarouselPic/list`,
      method:'get'
    })
}
// 正常商品详情页
export function getDetails(val) {
    return request({
      url:`${apiBase}/ruzhu/api/index/rzGoodsList/${val}`,
      method:'get'
    })
}
// 升级商品详情页
export function getresetDetails(params) {
  return request({
    url:`${apiBase}/ruzhu/api/index/rzUpgradeGoodsList`,
    method:'get',
    params:params
  })
}
//秒杀商品详情页
export function getSeckillGoods(seckillGoodsId) {
  return request({
    url:`${apiBase}/seckill/api/detail/${seckillGoodsId}`,
    method:'get'
  })
}
// 内存续费商品详情页
export function internalStorage(extensionOrderId) {
  return request({
    url:`${apiBase}/ruzhu/api/index/rzExpansionList?extensionOrderId=${extensionOrderId}`,
    method:'get'
  })
}

//确认订单页商品信息
export function confirmOrderDetail(data) {
    return request({
      url:`${apiBase}/api/OrderManage/getCommodityInformation`,
      method:'post',
      data
    })
}
// 微信支付
export function weixinPay(data) {
  return request({
    url:`${apiBase}/api/ruzhu/order/saveOrder`,
    method:'post',
    data
  })
}
// 微信查询订单状态
export function queryOrderStatus(orderId) {
  return request({
    url:`${apiBase}/api/ruzhu/order/query-order-status/${orderId}`,
    method:'get'
  })
}

// h5支付宝支付
export function alipayH5(data) {
  return request({
    url:`${apiBase}/api/ruzhu/order/alipay/orderH5`,
    method:'post',
    data
  })
}

// pc支付宝支付
export function alipayPc(data) {
  return request({
    url:`${apiBase}/api/ruzhu/order/alipay/saveOrder`,
    method:'post',
    data
  })
}
// 创建订单
export function createOrder(data) {
  return request({
    url: `${apiBase}/api/ruzhu/order/createOrder`,
    method: 'post',
    data
  })
}

export function createResetOrder(data) {
  return request({
    url: `${apiBase}/api/ruzhu/order/createUpgradeOrder `,
    method: 'post',
    data
  })
}

// 内存续费创建订单
export function createExpansionOrder(data) {
    return  request({
      url:`${apiBase}/api/ruzhu/order/createExpansionOrder`,
      method:'post',
      data
    })
}

// 获取订单
export function getOrderList(data) {
  return request({
    url:`${apiBase}/api/OrderManage/getRzOrderList`,
    method:'post',
    data
  })
}
// 取消订单
  export function cancelOrder(orderId) {
    return request({
      url:`${apiBase}/api/ruzhu/order/cancel/${orderId}`,
      method:'post'
    })
  }

  //秒杀倒计时重启
  export function hilarity(orderId) {
    return request({
      url:`${apiBase}/api/ruzhu/order/cancel/${orderId}`,
      method:'post'
    })
  }

  // api/ruzhu/order/refunds/${this.LoginUser.orderId}/${this.LoginUser.reason}
  export function handleRefund(orderId,reason){
    return request({
      url:`${apiBase}/api/ruzhu/order/refunds/${orderId}/${reason}`
    })
  }

  // 获取联系电话,邮箱
  export function getMessage(){
    return request({
      url:`${apiBase}/jy/serviceTel`,
      method:'get'
    })
  }

  //更新用户信息
 export function getwechatShare(url,goodsId,goodsType) {
  return request({
    url:`${apiBase}/jy/share?url=`+url+`&goodsId=`+goodsId+`&goodsType=`+goodsType,
    method:'get',
  })
}

// 使用方法
// import { getGoodList } from "@/api/home";
// async getList() {
//   const res = await getGoodList();
//   console.log('商品信息', res);
// },
