/*
 * @Author: HuangJ
 * @Date: 2021-03-24 11:20:29
 * @LastEditors: HuangJ
 * @LastEditTime: 2021-04-19 10:55:26
 * @Description: file content
 */
module.exports = {
  /**
   * @description 网站标题
   */
  title: '捷盈企业网站-入驻商城',
  /**
   * @description 是否显示 tagsView
   */
  tagsView: true,
  /**
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: 1,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description 是否只保持一个子菜单的展开
   */
  uniqueOpened: true,
  /**
   * @description token key
   */
  TokenKey: 'YSHOP-TOEKN',

  RefreshTokenKey: 'YSHOP-Refresh-Token',

  ExpiresInKey: 'YSHOP-Expires-In',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 1200000,
  /**
   * @description 是否显示logo
   */
  sidebarLogo: true,
  /**
   * 是否显示设置的底部信息
   */
  showFooter: false,
  /**
   * 底部文字，支持html语法
   */
  footerTxt: '© 2019 NQ <a href="http://www.apache.org/licenses/LICENSE-2.0" target="_blank">Apache License 2.0</a>',
  /**
   * 备案号
   */
  caseNumber: '闽ICP备123456789号'
}
