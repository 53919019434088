
<template>
  <div id="register">
    <el-dialog title="注册" :close-on-click-modal="false" width="300px" center :visible.sync="isRegister">
      <!-- status-icon -->
      <el-form :model="RegisterUser" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="brandName">
          <el-input clearable placeholder="请输入公司名称" v-model="RegisterUser.brandName" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item prop="userName">
          <el-input placeholder="域名前缀" clearable v-model="RegisterUser.userName" maxlength="15">
            <template slot="append">{{domainName}}</template>
          </el-input>
        </el-form-item>
        <el-form-item prop="CellPhone">
          <el-input clearable v-model="RegisterUser.CellPhone" placeholder="请输入手机号(作为账号登录)" maxlength="11">
          </el-input>
        </el-form-item>

        <el-form-item prop="pass" >
          <el-input clearable  v-if="this.$device.mobile" type="number" class="hidden" placeholder="请输入密码" v-model="RegisterUser.pass" maxlength="18"></el-input>
          <el-input v-else clearable  type="password"  placeholder="请输入密码" v-model="RegisterUser.pass" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item prop="confirmPass" >
          <el-input clearable v-if="this.$device.mobile"  type="number" class="hidden" placeholder="请再次输入密码" v-model="RegisterUser.confirmPass"></el-input>
          <el-input clearable  v-else type="password"  placeholder="请再次输入密码" v-model="RegisterUser.confirmPass"></el-input>
        </el-form-item>
        <el-form-item prop="VerificationCode">
          <div>
            <el-input clearable v-model="RegisterUser.VerificationCode" style="width:60%;margin-right:4%" placeholder="请输入验证码" maxlength="6">
            </el-input>
            <el-button style="width: 36%" :disabled="disabled" @click="getCode">
              <span class="Time">{{btnTxt}}</span>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="Register" style="width:100%;">注册</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { register, sendSMS,selectOnTel,selectOneUserName,getDomainName } from "@/api/home";
export default {
  name: "MyRegister",
  props: ["register"],
  data () {
    // 校验公司域名的方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入域名前缀"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/
      // const userNameRule = /[a-zA-Z0-9_]{4,15}$/;
      // userNameRule.test(value)
      if (/[^\u4e00-\u9fa5]/.test(value)) {
        //判断数据库中是否已经存在该用户名
        const params ={
          userName: this.RegisterUser.userName
        }
        selectOneUserName(params).then(()=>{
              // console.log(data);
              this.$refs.ruleForm.validateField("checkPass");
              return callback();
            // else {
            //   return callback(new Error(data.data.msg));
            // }
        }).catch(err => {
          return callback(new Error(err.data.msg));
          });
      } else {
        return callback(new Error("不能输入中文"));
      }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      // /^[a-zA-Z]\w{5,17}$/
      const passwordRule = /\w{5,17}$/;
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(
          new Error("长度6-18之间")
        );
      }
    };
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入确认密码"));
      }
      // 校验是否以密码一致
      if (this.RegisterUser.pass != "" && value === this.RegisterUser.pass) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      } else {
        return callback(new Error("两次输入的密码不一致"));
      }
    };
    // 校验手机号
    let validatePhone = (rule, value, callback) => {
      // 判断输入框中是否输入手机号
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }

      let phoneRule = /^1[35789]\d{9}$/;
      //正则表达式进行验证手机号，从1开始，第二位是35789中的任意一位，以9数字结尾
      if (phoneRule.test(value)) {
         // 验证逻辑中添加判断，如果请求已发送，则直接返回
        if (this.isRequestSent) {
          return callback();
        }
        const params = {
          mobile: this.RegisterUser.CellPhone
        }
        selectOnTel(params).then(({data})=>{
          console.log(data);
              this.disabled = false;
              return callback();
        }) .catch(err => {
            this.disabled = true;
            return callback(new Error(err.data.msg));
          });
      } else {
        return callback(new Error("手机号格式不正确"));
      }
      // return callback();
    };
    return {
      btnTxt: "获取验证码",
      // 是否禁用  即点击之后一段时间内无法再点击
      disabled: true,
      isRegister: false, // 控制注册组件是否显示
      isRequestSent:false,
      domainName:'',
      RegisterUser: {
        name: "",
        pass: "",
        confirmPass: "",
        brandName: "",
        CellPhone: "",
        VerificationCode: "",
        userName:'',
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        userName: [{ validator: validateName, trigger: "change" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        confirmPass: [{ validator: validateConfirmPass, trigger: "blur" }],
        CellPhone: [
          // { required: true, trigger: "blur", message: "请输入11位手机号" },
          // {
          //   required: true,
          //   trigger: "blur",
          //   min: 11,
          //   max: 11,
          //   message: "长度不符合"
          // },
          { validator: validatePhone, trigger: "blur" }
        ],
        brandName: [
          { required: true, message: "请填写公司名称", trigger: "blur" }
        ],

        VerificationCode: [
          { required: true, trigger: "blur", message: "请输入6位验证码" },
          {
            required: true,
            trigger: "blur",
            min: 6,
            max: 6,
            message: "验证码错误"
          }
        ]
      }
    };
  },
  watch: {
    // 监听父组件传过来的register变量，设置this.isRegister的值
    register: function (val) {
      if (val) {
        this.isRegister = val;
      }
    },
    // 监听this.isRegister变量的值，更新父组件register变量的值
    isRegister: function (val) {
      if (!val) {
        this.$refs["ruleForm"].resetFields();
        this.$emit("fromChild", val);
      }
    }
  },
  mounted() {
    this.getDomainName()
  },
  methods: {
     async getDomainName(){
      // getDomainName().then(()=>{
      //   console.log(data);
      // })
      const res = await getDomainName();
      console.log(res);
      if (res.code==0) {
        this.domainName = res.data.domain
      }
    },
    async Register () {
      const valid = await new Promise(resolve => {
        this.$refs["ruleForm"].validate(valid => {
          resolve(valid);
        });
      });
      if (valid) {
        const params = {
          userName: this.RegisterUser.userName,
          passWord: this.RegisterUser.pass,
          brandName: this.RegisterUser.brandName,
          mobile: this.RegisterUser.CellPhone,
          code: this.RegisterUser.VerificationCode
        };

        try {
          register(params).then(({data})=>{
            console.log(data);
            this.isRegister = false;
             // 弹出通知框提示注册成功信息
            // this.notifySucceed(data.data.msg);
            this.notifySucceed(data.msg);
          }).catch(err=>{
            console.log(err);
            this.notifyError(err.data.msg);
          })
          // return res;
        } catch (error) {
          // console.error(error);
          // 返回登录错误
          // throw new Error("注册失败");
        }
      } else {
        // 校验未通过，返回 false
        return false;
      }
    },
    //获取手机验证码方法
    async getCode () {
      // 校验手机号码
      if (!this.RegisterUser.CellPhone) {
        //号码校验不通过
        this.$message({
          message: "请输入手机号",
          type: "warning"
        });
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/1[35789]\d{9}/.test(this.RegisterUser.CellPhone)) {
        // 失去焦点后自动触发校验手机号规则
      } else {
        this.time = 60;
        this.disabled = true;

        // 封装的axios接口
        const params = {
          phone: this.RegisterUser.CellPhone
        }
        console.log(params);
        const res = await sendSMS(params);
        console.log(res);
        if (res.code === 0) {
          //调用倒计时方法
          this.timer();
          this.$message({
            message: "发送成功",
            type: "success"
          });
          this.isRequestSent=true
        }
      }
    },
    // 倒计时方法
    timer () {
      if (this.time > 0) {
        this.time--;
        // console.log(this.time);
        this.btnTxt = this.time + "s";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnTxt = "获取验证码";
        this.disabled = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.vercode-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>