// import axios from 'axios'

// export default axios.create({
//     baseURL: '',
//     timeout: 10000
// })
import Vue from 'vue'
import axios from 'axios'
import router from '../router/index'
import qs from 'qs'
import store from '../store/index'
import merge from 'lodash/merge'
// import { clearLoginInfo } from '@/utils'

const http = axios.create({
    timeout: 1000 * 30,
    withCredentials: true,
    // website-api.jieyingsoft.com
    baseURL: 'https://website-api.jieyingsoft.com/fzjy',
    // baseURL: 'http://192.168.3.109:8089/fzjy',
    // Access-Control-Allow-Origin
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
    // Vue.cookie.get('name')
    config.headers['domainName'] = 'nihao'  // 请求头带上token
    return config
}, error => {
    // 跳转error页面
    router.push({ path: "/error" });
    return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
    // if (response.data && response.data.code === 401) { // 401, token失效
    //     // clearLoginInfo()
    //     // router.push({ name: 'login' })
    // }
    if (response.data.code === "401") {
        // 401表示没有登录
        // 提示没有登录
        Vue.prototype.notifyError(response.data.msg);
        // 修改vuex的showLogin状态,显示登录组件
        store.dispatch("setShowLogin", true);
    }
    if (response.data.code === "500") {
        // 500表示服务器异常
        // 跳转error页面
        router.push({ path: "/error" });
    }
    return response
}, error => {
    return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
    // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
    // return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName
    return actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefaultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefaultParams = true) => {
    var defaults = {
        't': new Date().getTime()
    }
    return openDefaultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefaultData 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefaultData = true, contentType = 'json') => {
    var defaults = {
        't': new Date().getTime()
    }
    data = openDefaultData ? merge(defaults, data) : data
    return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
