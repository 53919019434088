
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// import 'lib-flexible/flexible'
// 全局函数及变量
import Global from './Global'
Vue.use(Global)

// 可手机端测试
import Vconsole from 'vconsole'
 
let vConsole = new Vconsole()
 
Vue.use(vConsole);


// axios
import axios from './api'

Vue.prototype.$http = axios;
// 二维码生成器
import VueQriously from 'vue-qriously'
Vue.use(VueQriously)

// 判断使用设备
import VueDeviceDetector from 'vue-device-detector';

Vue.use(VueDeviceDetector);

// import Axios from 'axios';
// Axios.create({
//   timeout: 1000 * 30,
//   withCredentials: true,
//   // baseURL: 'https://website-api.jieyingsoft.com/fzjy',
//   // Access-Control-Allow-Origin
//   headers: {
//     'Content-Type': 'application/json; charset=utf-8'
//   }
// })
// Vue.prototype.$axios = Axios;
// // 全局请求拦截器
// Axios.interceptors.request.use(
//   config => {
//     // Vue.cookie.get('name') 
//     config.headers['domainName'] = 'nihao'
//     return config;
//   },
//   error => {
//     // 跳转error页面
//     router.push({ path: "/error" });
//     return Promise.reject(error);
//   }
// );
// // 全局响应拦截器
// Axios.interceptors.response.use(
//   res => {
//     if (res.data.code === "401") {
//       // 401表示没有登录
//       // 提示没有登录
//       Vue.prototype.notifyError(res.data.msg);
//       // 修改vuex的showLogin状态,显示登录组件
//       store.dispatch("setShowLogin", true);
//     }
//     if (res.data.code === "500") {
//       // 500表示服务器异常
//       // 跳转error页面
//       router.push({ path: "/error" });
//     }
//     return res;
//   },
//   error => {
//     // 跳转error页面
//     router.push({ path: "/error" });
//     return Promise.reject(error);
//   }
// );

// 全局拦截器,在进入需要用户权限的页面前校验是否已经登录
router.beforeResolve((to, from, next) => {
  const loginUser = store.state.user.user;
  // 判断路由是否设置相应校验用户权限
  if (to.meta.requireAuth) {
    if (!loginUser) {
      // 没有登录，显示登录组件
      store.dispatch("setShowLogin", true);
      if (from.name == null) {
        //此时，是在页面没有加载，直接在地址栏输入链接，进入需要登录验证的页面
        next("/");
        return;
      }
      // 终止导航
      next(false);
      return;
    }
  }
  next();
});

// 相对时间过滤器,把时间戳转换成时间
// 格式: 2020-02-25 21:43:23
Vue.filter('dateFormat', (dataStr) => {
  var time = new Date(dataStr);
  function timeAdd0(str) {
    if (str < 10) {
      str = '0' + str;
    }
    return str;
  }
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + timeAdd0(m) + '-' + timeAdd0(d) + ' ' + timeAdd0(h) + ':' + timeAdd0(mm) + ':' + timeAdd0(s);
});

//全局组件
import MyMenu from './components/MyMenu';
Vue.component(MyMenu.name, MyMenu);
import MyList from './components/MyList';
Vue.component(MyList.name, MyList);
import MyLogin from './components/MyLogin';
Vue.component(MyLogin.name, MyLogin);
import MyRegister from './components/MyRegister';
Vue.component(MyRegister.name, MyRegister);
import MyforgetPassword from './components/MyforgetPassword';
Vue.component(MyforgetPassword.name, MyforgetPassword);

// const TerserPlugin = require('terser-webpack-plugin')
// module.exports = {
//   configureWebpack: {
//     // 去除页面所有console
//     optimization: {
//       minimizer: [new TerserPlugin(
//         {
//           terserOptions: {
//             compress: { warnings: false, drop_console: true, drop_debugger: true, pure_funcs: ["console.log"] }
//           }
//         }
//       )],
//     },
//   },
// }

// Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
