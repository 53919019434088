
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [

  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../components/Error.vue')
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../views/Goods.vue')
  },
  {
    path: '/seckillAll',
    name: 'SeckillAll',
    component: () => import('../views/seckillAll.vue')
  },
  {
    path:'/goodsAll',
    name:'GoodsAll',
    component: () => import('../views/goodsAll.vue')
  },
  {
    path: '/goods/details',
    name: 'Details',
    meta: {
      keepAlive: false,  //代表不需要缓存
    },
    component: () => import('../views/Details.vue')
  },
  {
    path: '/goods/resetdetails',
    name: 'resetDetails',
    meta: {
      keepAlive: false,  //代表不需要缓存
    },
    component: () => import('../views/resetDetails.vue')
  },
  // 
  {
    path: '/goods/internalStorageDetails',
    name: 'internalStorageDetails',
    component: () => import('../views/internalStorageDetails.vue')
  },
  {
    path: '/goods/seckilldetails',
    name: 'SeckillDetails',
    meta: {
      keepAlive: false,  //代表不需要缓存
    },
    component: () => import('../views/SeckillDetails.vue')
  },
  {
    path: '/UserCenter',
    name: 'UserCenter',
    component: () => import('../views/UserCenter.vue')
  },
  {
    path: '/MyOrder',
    name: 'MyOrder',
    component: () => import('../views/modules/MyOrder.vue')
  },
  {
    path: '/MOrderDetail',
    name: 'MOrderDetail',
    component: () => import('../views/modules/MOrderDetail.vue')
  },
  {
    path: '/OrderDetails',
    name: 'OrderDetails',
    component: () => import('../views/OrderDetails.vue'),
    meta: {
      requireAuth: true // 需要验证登录状态
    }
  },
  {
    path: '/UserCenter',
    name: 'UserCenter',
    component: () => import('../views/UserCenter.vue'),
    meta: {
      requireAuth: true // 需要验证登录状态
    }
  },
  {
    path: '/confirmOrder',
    name: 'ConfirmOrder',
    component: () => import('../views/ConfirmOrder.vue'),
    meta: {
      requireAuth: true, // 需要验证登录状态
      keepAlive: false,  //不需要缓存
    }
  }
]

const router = new Router({
  // base: '/dist',
  // mode: 'history',
  routes,
  scrollBehavior: () => ({ y: 0 })
})

/* 由于Vue-router在3.1之后把$router.push()方法改为了Promise。所以假如没有回调函数，错误信息就会交给全局的路由错误处理。
vue-router先报了一个Uncaught(in promise)的错误(因为push没加回调) ，然后再点击路由的时候才会触发NavigationDuplicated的错误(路由出现的错误，全局错误处理打印了出来)。*/
// 禁止全局路由错误处理打印
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
