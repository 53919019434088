<template>
  <div id="forgetPassword">
    <el-dialog
      title="修改密码"
      :close-on-click-modal="false"
      width="300px"
      center
      :visible.sync="isForgetPassword"
    >
      <!--  status-icon -->
      <el-form
        :model="ForgetPassword"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <!-- <el-form-item prop="username">
          <el-input
            prefix-icon="el-icon-user-solid"
            placeholder="请输入需要修改密码的账号"
            v-model="ForgetPassword.name"
          ></el-input>
        </el-form-item> -->
        <el-form-item prop="CellPhone">
          <el-input
            v-model="ForgetPassword.CellPhone"
            maxlength="11"
            clearable
            placeholder="请输入手机号"
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="VerificationCode">
          <div class="vercode-box">
            <el-input
              style="width: 60%; margin-right: 4%"
              v-model="ForgetPassword.VerificationCode"
              clearable
              placeholder="请输入验证码"
              maxlength="6"
            >
            </el-input>
            <el-button style="width: 36%" :disabled="disabled" @click="getCode">
              <span class="Time">{{ btnTxt }}</span>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="pass">
          <!-- prefix-icon="el-icon-view" -->
          <el-input
          v-if="this.$device.mobile" 
            clearable
            type="number"
            class="hidden"
            placeholder="请输入修改密码"
            v-model="ForgetPassword.pass"
          ></el-input>
          <el-input
            v-else
            clearable
            type="password"
            placeholder="请输入修改密码"
            v-model="ForgetPassword.pass"
          ></el-input>
        </el-form-item>
        <el-form-item prop="confirmPass">
          <!--  prefix-icon="el-icon-view" -->
          <el-input
            v-if="this.$device.mobile"
            clearable
            type="number"
            class="hidden"
            placeholder="请再次输入修改密码"
            v-model="ForgetPassword.confirmPass"
          ></el-input>
          <el-input
            v-else
            clearable
            type="password"
            placeholder="请再次输入修改密码"
            v-model="ForgetPassword.confirmPass"
          ></el-input>
        </el-form-item>
        <!-- ref="phone" -->

        <el-form-item>
          <el-button
            size="medium"
            type="primary"
            @click="notarize"
            style="width: 100%"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  modificationPass,
  UpdatePasswordSendSMS,
  selectManageTel,
} from '@/api/home'
import { mapActions } from 'vuex'
export default {
  name: 'MyforgetPassword',
  props: ['forgetPassword'],
  data() {
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入用户名'))
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/
      const userNameRule = /[a-zA-Z0-9_]{4,15}$/
      if (userNameRule.test(value)) {
        const params = {
          mobile: this.ForgetPassword.CellPhone,
        }
        selectManageTel(params)
          .then(({ data }) => {
            console.log(data)
            this.disabled = false
            return callback()
          })
          .catch((err) => {
            return callback(new Error(err.data.msg))
          })
      } else {
        return callback(new Error('长度5-16之间,允许字母数字下划线'))
      }
    }
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入修改密码'))
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      // /^[a-zA-Z]\w{5,17}$/
      const passwordRule = /\w{5,17}$/
      if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField('checkPass')
        return callback()
      } else {
        return callback(
          // 允许字母数字和下划线
          new Error('长度6-18之间')
        )
      }
    }
    // 确认密码的校验方法
    let validateConfirmPass = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入确认修改密码'))
      }
      // 校验是否以密码一致
      if (
        this.ForgetPassword.pass != '' &&
        value === this.ForgetPassword.pass
      ) {
        this.$refs.ruleForm.validateField('checkPass')
        return callback()
      } else {
        return callback(new Error('两次输入的密码不一致'))
      }
    }
    // 校验手机号
    // let isRequestSent = false
    let validatePhone = (rule, value, callback) => {
      // 判断输入框中是否输入手机号
      if (!value) {
        return callback(new Error('手机号不能为空'))
      }
      let phoneRule = /^1[35789]\d{9}$/
      //正则表达式进行验证手机号，从1开始，第二位是35789中的任意一位，以9数字结尾
      if (phoneRule.test(value)) {
        // this.disabled = false;
        if (this.isRequestSent) {
          return callback()
        }
        const params = {
          mobile: this.ForgetPassword.CellPhone,
        }
        selectManageTel(params)
          .then(({ data }) => {
            console.log(data)
            this.disabled = false
            return callback()
          })
          .catch((err) => {
            console.log(err)
            this.disabled = true
            return callback(new Error(err.data.msg))
          })
      } else {
        this.disabled = true
        return callback(new Error('手机号格式不正确'))
      }
      // return callback();
    }
    return {
      btnTxt: '获取验证码',
      // 是否禁用  即点击之后一段时间内无法再点击
      disabled: true,
      isRequestSent: false,
      isForgetPassword: false, // 控制忘记密码组件是否显示
      ForgetPassword: {
        name: '',
        pass: '',
        confirmPass: '',
        // brandName: "",
        CellPhone: '',
        VerificationCode: '',
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        name: [{ validator: validateName, trigger: 'blur' }],
        pass: [{ validator: validatePass, trigger: 'blur' }],
        confirmPass: [{ validator: validateConfirmPass, trigger: 'blur' }],
        CellPhone: [{ validator: validatePhone, trigger: 'blur' }],
        VerificationCode: [
          { required: true, trigger: 'blur', message: '请输入6位验证码' },
          {
            required: true,
            trigger: 'blur',
            min: 6,
            max: 6,
            message: '验证码错误',
          },
        ],
      },
    }
  },
  watch: {
    // 监听父组件传过来的register变量，设置this.isForgetPassword的值
    forgetPassword: function (val) {
      if (val) {
        this.isForgetPassword = val
      }
    },
    // 监听this.isForgetPassword变量的值，更新父组件forgetPassword变量的值
    isForgetPassword: function (val) {
      if (!val) {
        this.$refs['ruleForm'].resetFields()
        this.$emit('fromChild', val)
      }
    },
  },
  methods: {
    ...mapActions(['setUser']),
    async notarize() {
      const valid = await new Promise((resolve) => {
        this.$refs['ruleForm'].validate((valid) => {
          resolve(valid)
        })
      })
      if (valid) {
        const params = {
          username: this.ForgetPassword.name,
          passWord: this.ForgetPassword.pass,
          newPassWord: this.ForgetPassword.confirmPass,
          // brandName: this.ForgetPassword.brandName,
          mobile: this.ForgetPassword.CellPhone,
          code: this.ForgetPassword.VerificationCode,
        }
        modificationPass(params)
          .then(({ data }) => {
            console.log(data)

            this.isForgetPassword = false
            // 弹出通知框提示注册成功信息
            this.notifySucceed(data.msg)
            localStorage.clear()
            // 清空vuex登录信息
            this.setUser('')
            console.log('进来了')
            // this.$router.push("/");
            this.$router.push('/').then(() => {
              console.log('进来了')
              location.reload()
            })
          })
          .catch((err) => {
            this.notifyError(err.msg)
          })
      } else {
        return false
      }
    },
    //获取手机验证码方法
    async getCode() {
      // 校验手机号码
      if (!this.ForgetPassword.CellPhone) {
        //号码校验不通过
        this.$message({
          message: '请输入手机号',
          type: 'warning',
        })
        //正则判断 从1开始，第二位是35789中的任意一位，以9数字结尾
      } else if (!/1[35789]\d{9}/.test(this.ForgetPassword.CellPhone)) {
        // 失去焦点后自动触发校验手机号规则
      } else {
        this.time = 60
        this.disabled = true

        // 封装的axios接口
        const params = {
          phone: this.ForgetPassword.CellPhone,
        }
        UpdatePasswordSendSMS(params)
          .then(() => {
            this.timer()
            this.$message({
              message: '发送成功',
              type: 'success',
            })
            this.isRequestSent = true
          })
          .catch((err) => {
            this.notifyError(err?.data?.msg)
          })
      }
    },
    // 倒计时方法
    timer() {
      if (this.time > 0) {
        this.time--
        // console.log(this.time);
        this.btnTxt = this.time + 's'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btnTxt = '获取验证码'
        this.disabled = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.vercode-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
</style>
